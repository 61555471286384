<template>
  <slot />
</template>

<script setup lang="ts">
import useAuthStore from '~/features/auth/store'

const authStore = useAuthStore()

const $q = useQuasar()

onMounted(() => {
  if (authStore.theme.type === 'dark') {
    document.querySelector('html')?.classList.add('dark', 'neo')
    $q.dark.set(true)
  } else {
    document.querySelector('html')?.classList.remove('dark')
    $q.dark.set(false)
  }

  if (authStore.themeStyle.type === 'neo') {
    document.querySelector('html')?.classList.add('neo')
  } else {
    document.querySelector('html')?.classList.add('chowey')
    document.querySelector('html')?.classList.remove('neo')
  }
})
</script>
